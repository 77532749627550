.avatar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;  /* Pour s'assurer que le conteneur ne dépasse pas */
    margin: 20px 0;  /* Espacement au-dessus et en-dessous de l'avatar */
}

.avatar-image {
    max-width: 40%;  /* L'image prend % de la largeur de son conteneur, mais cela peut être ajusté */
    height: auto;    /* Pour maintenir les proportions de l'image */

}


@media (min-width: 768px) {
    /*  ajustements pour la tablette ici.  */
    .avatar-image {
        max-width: 25%;  /* Ajustement de la largeur de l'image pour les tablettes */
    }
}



@media (min-width: 1024px) {
    /*  ajustements pour l'ordinateur de bureau ici. P */
    .avatar-image {
        max-width: 20%;  /* Ajustement de la largeur de l'image pour les ordinateurs de bureau */
    }
}