
/* Réinitialisation des styles par défaut */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* Mobile First */

/* Styles pour mobile */

.maintenance-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;

    position: relative;

    color: #333;
}

.maintenance-container::-webkit-scrollbar {
    display: none; /* pour Chrome, Safari et Opera */
}
.maintenance-container {
    -ms-overflow-style: none;  /* pour IE et Edge */
    scrollbar-width: none; /* pour Firefox */
}


.maintenance-title {
    font-size: 2.5em;
    /*margin-bottom: 1em;*/
    margin: 0.5em 0;  /* ajusté pour économiser de l'espace vertical */
}


.maintenance-message {
    font-size: 1.5em;
    margin: 0.5em 0;  /* ajusté pour économiser de l'espace vertical */
}


.maintenance-container.dark-mode .maintenance-title,
.maintenance-container.dark-mode .maintenance-message {
    color: #f3f4f6;  /* Couleur du texte en mode sombre */
}

.maintenance-container.light-mode .maintenance-title,
.maintenance-container.light-mode .maintenance-message {
    color: #333;  /* Couleur du texte en mode clair */
}



/* Styles du bouton pour changer de mode */
.maintenance-button-color {
    padding: 10px 20px;
    font-size: 1em;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    margin: 1em 0;  /* ajusté pour économiser de l'espace vertical */
    transition: background-color 0.3s ease;
    background-color: transparent;
    border: none;  /* pour supprimer la bordure si elle existe */
    outline: none; /* pour supprimer le contour lorsqu'il est cliqué ou mis en focus */
    width: 40px;  /* Vous pouvez ajuster cette valeur selon vos besoins */
    height: 40px; /* Vous pouvez ajuster cette valeur selon vos besoins */
    object-fit: contain; /* S'assure que l'image s'ajuste à l'intérieur du cadre défini */
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px; /* Ajustez selon vos besoins */
    margin-bottom: 20px; /* Ajustez selon vos besoins */
}

.light-mode .maintenance-button-color {
    border: none;  /* pour supprimer la bordure si elle existe */
    outline: none; /* pour supprimer le contour lorsqu'il est cliqué ou mis en focus */
}

.dark-mode .maintenance-button-color {
    border: none;  /* pour supprimer la bordure si elle existe */
    outline: none; /* pour supprimer le contour lorsqu'il est cliqué ou mis en focus */
}

.maintenance-button-color:hover {
    opacity: 0.8;
}

/* Pour le mode sombre/clair - mobile */
body.light-mode {
    background-color: white;
}

body.dark-mode {
    background-color: #343541;

}

canvas {
    width: 100%;
    height: 30vh; /* La hauteur que vous préférez pour la sphère sur mobile */
    max-width: 500px; /* Une largeur maximale pour s'assurer que la sphère ne devient pas trop grande sur les grands écrans */
    margin: 0 auto;
    display: block;

    /*margin-bottom: 20px; /* Espacement sous la sphère */
}


html, body {
    margin: 0;
    padding: 0;
    background-color: white; /* Mode clair par défaut */
    height: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

}


.maintenance-title, .maintenance-message {
    margin-top: 0;
}


.content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}



/*Pour téléphone pliable Galaxy Fold 2 */
@media only screen and (max-width: 280px) {

    .maintenance-title {
        font-size: 2em; /* Ajustez selon  besoins */
    }

    .maintenance-message {
        font-size: 1.2em; /* Ajustez selon  besoins */
    }
}


/* Styles pour tablettes */
@media (min-width: 768px) {

    html, body {
        overflow: hidden;
    }

    .maintenance-container {
        /* Styles spécifiques aux tablettes */
        padding: 20px;
    }

    .maintenance-title {
        font-size: 3em; /* augmentation de la taille de la police pour les titres sur tablette */
        margin: 30px 0; /* Plus d'espacement autour du titre */
    }

    .maintenance-message {
        font-size: 1.8em; /* augmentation de la taille de la police pour le message sur tablette */
        margin: 30px 0; /* Plus d'espacement autour du message */
    }

}


/* Styles pour ordinateurs de bureau */
@media (min-width: 1024px) {

    body {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh; /* Ajout pour s'assurer que le body prend toute la hauteur */
    }

    .maintenance-container {
        width: 100%; /* ou la largeur que vous préférez */
        padding: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around; /* Distribue l'espace uniformément entre les éléments */
        /* ... vos styles existants ... */
        padding-top: 80px; /* ajustement de l'espacement pour les écrans de bureau */

    }


    .content-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .maintenance-title {
        font-size: 3.5em;
        margin: 20px 0; /* Réduisez les marges pour le titre */
    }

    .maintenance-message {
        font-size: 2em;
        margin: 20px 0; /* Réduisez les marges pour le message */
    }
}