.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 1.3rem 10%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 100;

}

.header::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    backdrop-filter: blur(50px);
    z-index: -1;
}

.header::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    transition: width 0.3s;  /* Transition pour l'animation du trait */
    transition: .5s;
}

.header:hover:after {
    left: 100%;
}



.logo {
    font-size: 2rem;
    color: white;
    text-decoration: none;
    font-weight: 700;
}

.navbar a {
    font-size: 1.15rem;
    color: white;
    text-decoration: none;
    font-weight: 500;
    margin-left: 2.5rem;
    position: relative;  /* Pour permettre la positionnement de l'élément pseudo ::after */
    transition: color 0.3s; /* Transition pour le changement de couleur du texte */
    padding-bottom: 5px; /* Ajout d'un peu d'espace en dessous du texte pour l'animation */
}

.navbar a::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;  /* On commence l'animation au milieu du lien */
    width: 0;
    height: 2px;
    background-color: dodgerblue;
    transition: all 0.5s;
    transform: translateX(-50%); /* Cela centrera le trait animé sous le texte */
}

.navbar a:hover {
    color: dodgerblue;  /* Couleur du texte lors du hover */
}

body.dark-mode .navbar a:hover {
    color: dodgerblue;
}

.navbar a:hover::after {
    width: 100%;  /* Lors du hover, le trait s'étend sur toute la largeur du lien */
}

.icons {
    position: absolute;
    right: 5%;
    font-size: 2.8rem;
    color: white;
    cursor: pointer;
    display: none;

}

#check {
    display: none;
}


/* BREAKPOINTS */
@media (max-width: 992px) {
    .header {
        padding: 1.3rem 5%
    }
}

@media (max-width: 768px) {
    .icons {
        display: inline-flex;
    }

    #check:checked~.icons #menu-icon {
     display: none;
    }

    .icons #close-icon {
        display: none;
    }
        #check:checked~.icons #close-icon {
            display: block;
        }

    .navbar {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: 0;
        background: rgba(0, 0, 0, .1);
        backdrop-filter: blur(50px);

        overflow: hidden;
        transition: .3s ease;

    }

        #check:checked~.navbar {
            height: 17.7rem;
        }

    .navbar a {
        display: block;
        font-size: 1.1rem;
        margin: 1.5rem 0;
        text-align: center;
        transform: translateY(-50px);
        opacity: 0;
        transition: .3s ease;
    }

    #check:checked~.navbar a {
        transform: translateY(0);
        opacity: 1;
        transition-delay: calc(.15s * var(--i));
    }

    .header::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        backdrop-filter: blur(50px);
        z-index: -1;
    }

    .header.active::before {
        background: rgba(0, 0, 0, .1);
    }
}


.header .logo,
.navbar a,
.icons {
    color: black;  /* Couleur du texte en mode clair */
}


body.dark-mode .header .logo,
body.dark-mode .navbar a,
body.dark-mode .icons {
    color: white;  /* Couleur du texte en mode sombre, qui est presque blanc */
}
